






























import Vue from 'vue'
import AzureRetirementEnvironmentStatus from './AzureRetirementEnvironmentStatus.vue'
import IAzureRetirement from '../models/IAzureRetirement'

export default Vue.extend({
  components: {
    AzureRetirementEnvironmentStatus
  },
  props: {
    azureRetirement: Object as () => IAzureRetirement
  }
})
