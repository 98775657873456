<template>
  <div>
    <span
      v-for="(azureRetirementFilter, i) in azureRetirementFilters"
      :key="azureRetirementFilter.name"
    >
      <router-link
        :class="{ active: isActive(azureRetirementFilter) }"
        :to="getRoute(azureRetirementFilter)"
        @click.native="onClick"
        >{{ azureRetirementFilter.name }} ({{ azureRetirementFilter.count }})</router-link
      >
      <span class="mx-2" v-if="i < azureRetirementFilters.length - 1">|</span>
    </span>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapGetters, mapActions } = createNamespacedHelpers('AzureRetirements')

export default {
  props: {
  },
  watch: {
    parentId: {
      handler(val) {
        this.getFilterCounts(val)
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters(['azureRetirementFilters'])
  },
  methods: {
    ...mapActions(['getFilterCounts']),
    getRoute(filter) {
      const query = { ...this.$route.query }
      query.filter = filter.name

      delete query.page

      return { name: 'Azure Retirements', query }
    },
    isActive(filter) {
      return filter.name === this.$route.query.filter
    },
    onClick() {
      this.getFilterCounts()
    }
  }
}
</script>

<style scoped>
a {
  text-decoration: none;
}
a.active {
  font-weight: bold;
}
</style>
