import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDataTable,_vm._g(_vm._b({attrs:{"item-key":"id","headers":_vm.visibleHeaders.filter(function (h) { return !_vm.dense || !h.denseHidden; }),"footer-props":_vm.footerProps,"items":_vm.azureRetirements,"loading":_vm.loading,"server-items-length":_vm.azureRetirementsCount,"options":_vm.options,"must-sort":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('TextField',{attrs:{"autofocus":"","prepend-inner-icon":"$search","placeholder":_vm.searchPlaceholder,"hide-details":"","clearable":""},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})]},proxy:true},{key:"item.header",fn:function(ref){
var item = ref.item;
return [_c(VRow,[_c('b',[_vm._v(_vm._s(item.header))])]),_c(VRow,[_c('span',[_vm._v("["),_c('a',{attrs:{"href":item.url,"target":"blank"}},[_vm._v(_vm._s(item.url))]),_vm._v("]")])])]}},{key:"item.modifiedOn",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(_vm._f("date")(value)))]}},{key:"item.targetRetirementDate",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(_vm._f("date")(value)))]}},{key:"item.environments",fn:function(ref){
var item = ref.item;
return [(!item.isRemoved)?_c('AzureRetirementEnvironmentStatuses',{staticClass:"text-no-wrap",attrs:{"azureRetirement":item},on:{"publish":function($event){return _vm.$emit('publish', item)},"promote":function($event){return _vm.$emit('promote', item)},"unpublish":function($event){return _vm.$emit('unpublish', { environment: $event.environment, azureRetirement: item })}}}):_c('span',[_vm._v(_vm._s(item.note))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!item.isRemoved)?_c(VBtn,{attrs:{"icon":"","title":"Archive"},on:{"click":function($event){return _vm.$emit('archive', item)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("$archive")])],1):_c(VBtn,{attrs:{"icon":"","title":"Restore"},on:{"click":function($event){return _vm.$emit('restore', item)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("$restore")])],1)]}}],null,true)},'v-data-table',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }